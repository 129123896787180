import React, { useMemo, useState } from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

interface TouchTooltipProps extends TooltipProps {
  disabled?: boolean;
}

const TouchTooltip: React.FC<TouchTooltipProps> = function TouchTooltip({ disabled, ...props }) {
  const [open, setOpen] = useState(false);

  const shouldOpen = useMemo(
    () => (open && !disabled && props.open !== false) || !!props.open,
    [props.open, open, disabled],
  );

  return (
    <Tooltip
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      open={shouldOpen}
      onOpen={() => setOpen(!disabled)}
      onClose={() => setOpen(false)}
      onMouseOver={() => setOpen(!disabled)}
      disableHoverListener={props.disableHoverListener || disabled}
      disableFocusListener={props.disableFocusListener || disabled}
      disableInteractive={props.disableInteractive || disabled}
      disableTouchListener={props.disableTouchListener || disabled}
      enterTouchDelay={0}
      leaveTouchDelay={0}
    />
  );
};

TouchTooltip.defaultProps = {
  disabled: false,
};

export default TouchTooltip;
