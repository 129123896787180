import { FC } from 'react';
import TouchTooltip from '../../TouchTooltip/TouchTooltip';

const tooltipTitle = (text: string) => <div>{text}</div>;

interface EllipsisTextProps {
  text: string;
  length: number;
  tail?: string;
  tailClassName?: string;
}

const EllipsisText: FC<EllipsisTextProps> = function EllipsisText(props) {
  const { text, length, tail, tailClassName } = props;

  if (text.length <= length) {
    return <span>{text}</span>;
  }

  const displayText = text.slice(0, length - (tail?.length || 3));

  return (
    <TouchTooltip title={tooltipTitle(text)} arrow placement="top">
      <span>
        {displayText}
        <span className={tailClassName}>{tail}</span>
      </span>
    </TouchTooltip>
  );
};

EllipsisText.defaultProps = {
  tail: '...',
  tailClassName: 'more',
};

export default EllipsisText;
