const GET_EMPLOYEES = 'employees/get';
const ADD_EMPLOYEE = 'employees/add';
const UPDATE_EMPLOYEE = 'employees/update';
const RESET_EMPLOYEE_PASSWORD = 'employees/reset_password';
const UPDATE_EMPLOYEE_ANNUAL_LEAVE = 'employees/update_annual_leave';
const DELETE_EMPLOYEE = 'employees/delete';
const IMPORT_EMPLOYEE = 'employees/import';
const UPDATE_EMPLOYEE_ROLE = 'employees/update_role';
const CHECK_EMPLOYEE_ACCOUNT = 'employees/check_account';
const GET_COUNTRIES = 'employees/get_countries';
const GET_EMPLOYEE_TRANSPORTATION = 'employee/get/transportation';
const POST_EMPLOYEE_TRANSPORTATION = 'employee/post/transportation';

export default {
  GET_EMPLOYEES,
  ADD_EMPLOYEE,
  UPDATE_EMPLOYEE,
  RESET_EMPLOYEE_PASSWORD,
  UPDATE_EMPLOYEE_ANNUAL_LEAVE,
  DELETE_EMPLOYEE,
  IMPORT_EMPLOYEE,
  UPDATE_EMPLOYEE_ROLE,
  CHECK_EMPLOYEE_ACCOUNT,
  GET_COUNTRIES,
  GET_EMPLOYEE_TRANSPORTATION,
  POST_EMPLOYEE_TRANSPORTATION,
};
