import {
  AddEmployeeParams,
  AddEmployeesResponseDataResult,
  CheckAccountResponseDataResult,
  Employee,
  TransportationHistoryItem,
  UpdateEmployeeParams,
  UpdateEmployeeRoleParams,
  PostTransportationData,
} from '../types/Employees';
import api from './axios';
import { Response } from '../types/Response';
import { EmployeesUnAvailability } from '../types/OpenShift';
import { CompanyEmployeesResponse } from '../types/WeeklySchedule';

const getEmployeeUnavailabilities = (branchId: number, date: string) =>
  api.get(`/branch/${branchId}/unavailabilities?date=${date}`) as Promise<
    Response<EmployeesUnAvailability>
  >;

const putEmployee = (accountId: number, data: UpdateEmployeeParams) =>
  api.put(`account/${accountId}`, data) as Promise<Response<boolean>>;

const putEmployeeRole = (
  branchId: number,
  accountId: number,
  data: Omit<UpdateEmployeeRoleParams, 'role_name'>,
) => api.put(`branch/${branchId}/account/${accountId}`, data) as Promise<Response<boolean>>;
const getEmployees = (branchId: number, date: string) =>
  api.get(`branch/${branchId}/employees${date ? `?start_date=${date}` : ''}`) as Promise<
    Response<Employee[]>
  >;
const deleteEmployee = (branchId: number, accountId: number) =>
  api.delete(`branch/${branchId}/account/${accountId}`) as Promise<Response<boolean>>;
const getCompanyEmployees = (branchId: number, date: string) =>
  api.get(`branch/${branchId}/company_employees${date ? `?start_date=${date}` : ''}`) as Promise<
    Response<CompanyEmployeesResponse>
  >;
const postEmployee = (branchId: number, data: AddEmployeeParams) =>
  api.post(`branch/${branchId}/employee`, data) as Promise<
    Response<AddEmployeesResponseDataResult>
  >;
const getEmployee = (branchId: number, data: { email: string }) =>
  api.post(`branch/${branchId}/check_account`, data) as Promise<
    Response<CheckAccountResponseDataResult>
  >;

const putEmployeeAnnualLeaveCredit = (
  companyId: number,
  accountId: number,
  data: { annual_leave_credit: number },
) =>
  api.put(`/company/${companyId}/account/${accountId}/annual_leave`, data) as Promise<
    Response<boolean>
  >;

const resetEmployeePassword = (accountId: number, newPassword: string) =>
  api.put(`account/${accountId}/reset_password`, {
    password: newPassword,
  }) as Promise<Response<boolean>>;

const getEmployeeTranportationHistory = (branchId: number, accountId: number) =>
  api.get(`transportation/branch/${branchId}/account/${accountId}`) as Promise<
    Response<TransportationHistoryItem[]>
  >;

const postTranportation = (data: PostTransportationData) =>
  api.post(`transportation/branch/${data.branchId}/account/${data.accountId}/batch`, {
    changes: data.changes,
  }) as Promise<Response<boolean>>;

export default {
  getCompanyEmployees,
  getEmployee,
  getEmployeeUnavailabilities,
  getEmployees,
  postEmployee,
  putEmployee,
  putEmployeeRole,
  deleteEmployee,
  putEmployeeAnnualLeaveCredit,
  resetEmployeePassword,
  getEmployeeTranportationHistory,
  postTranportation,
};
